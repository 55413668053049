import { ComboBox, IComboBox, IComboBoxOption } from "@fluentui/react";
import { Field } from "@fluentui/react-components";
import React, { FormEvent, useCallback, useMemo } from "react";
import { useHideUnimportantErrors } from "../bestellung/hooks/useHideUninportantErrors";

interface ISelectFieldProps<T> {
  options: Array<Partial<T>>;
  optionBuilder: (item: Partial<T>) => IComboBoxOption;
  onChange: (value: string, data?: T) => void;
  value: string;
  defaultValue: string;
  title: string;
  disabled?: boolean;
  id: string;
  finder: (item: Partial<T>) => boolean;
  onNotFindable?: () => Partial<T>;
  orientation?: "vertical" | "horizontal";
  style?: React.CSSProperties;
  freetext?: boolean;
}

function orderData<T>(
  items: Array<Partial<T>>,
  finder: (item: Partial<T>) => boolean,
  onNotFindable?: () => Partial<T>
) {
  let retval = [...items];
  let obj = items.find(finder);
  if (obj) {
    let index = items.indexOf(obj);

    if (index !== -1) {
      retval.splice(index, 1); // Remove the object from its current position
      retval.unshift({ ...obj }); // Insert the object at the beginning
    }
  } else if (onNotFindable) {
    let tmp = onNotFindable();
    if (tmp) {
      retval.unshift({ ...tmp }); // Insert the object at the beginning
    }
  }
  return retval;
}

export function SelectField<T>(props: ISelectFieldProps<T>) {
  let retval = useMemo(
    () =>
      props.onNotFindable
        ? orderData<T>(props.options, props.finder, props.onNotFindable)
        : props.options,
    [props]
  );
  let ops: IComboBoxOption[] = useMemo(
    () =>
      props.freetext
        ? [
            ...retval.map(props.optionBuilder),
            { key: props.value, text: props.value },
          ]
        : retval.map(props.optionBuilder),
    [props, retval]
  );
  const onChange = useCallback(
    (
      event: FormEvent<IComboBox>,
      option?: IComboBoxOption | undefined,
      index?: number | undefined,
      value?: string | undefined
    ) => {
      let key = option?.key + "";
      if (props.freetext === false) {
        props.onChange(key, option?.data);
      } else {
        props.onChange(value ?? "", option?.data);
      }
    },
    [props, ops]
  );

  useHideUnimportantErrors();
  return (
    <Field
      style={props.style}
      orientation={props.orientation}
      label={props.title}
    >
      <ComboBox
        selectedKey={ops.find((pr) => pr.text === props.value)?.key}
        defaultValue={props.defaultValue}
        text={props.value}
        disabled={props.disabled}
        autoComplete="on"
        allowFreeInput
        allowFreeform={props.freetext}
        translate="no"
        id={props.id}
        options={ops}
        onChange={onChange}
      />
    </Field>
  );
}
