import React, { useCallback, useState } from "react";
import Header from "./Header";
import { SidebarNavigation } from "./SidebarNavigation";
import TabsView from "./tabsview/TabsView";
import Footer from "./Footer";
import { Bestellung as Bestellungsdata, useGetUserQuery } from "../gql/graphql";
import { BestellungenView } from "../bestellung/BestellungenView";
import { BestellungForm } from "../bestellung/BestellungForm";
import { BelagssortenView } from "../bestellung/belag/BelagssortenView";
import { DokumenteView } from "../dokumente/DokumenteView";
import { WartungsListe } from "../produktion/WartungsIntervalle";
import { Nachrichten } from "../administration/Nachrichten";
import { MitarbeiterView } from "../administration/MitarbeiterView";
import { BaustellenView } from "../administration/BaustellenView";
import { Start } from "./start/Start";
import { BestellungArchiv } from "../bestellung/BestellungArchiv";
import {
  DialogSurface,
  Button,
  Dialog,
  DialogBody,
  DialogActions,
  FluentProvider,
  webLightTheme,
} from "@fluentui/react-components";

interface ITab {
  id: string;
  element: React.ReactElement;
}

export type RoleType =
  | "Admin"
  | "Baufuehrer"
  | "Dispo"
  | "None"
  | "Mischmeister"
  | "GV"
  | "Firmen Admin";

export function Content(props: { handleLogoutRedirect: () => void }) {
  const { data: me, loading } = useGetUserQuery();
  const [openDialog, setOpenDialog] = useState(false);
  const [tabs, setTabs] = useState<ITab[]>([
    { id: "Start", element: <Start /> },
  ]);
  const [sandwitchOpen, setSandwitchOpen] = useState(true);

  const [selectedValue, setSelectedValue] = useState<string>("Start");

  function useTabManager(
    tabs: ITab[],
    setTabsForManager: React.Dispatch<
      React.SetStateAction<
        {
          id: string;
          element: React.ReactElement;
          warn?: boolean;
        }[]
      >
    >,
    setSelectedValueForManager: React.Dispatch<React.SetStateAction<string>>
  ) {
    return useCallback(
      (id: string, element: JSX.Element, warn?: boolean) => () => {
        if (!tabs.map((pr) => pr.id).includes(id)) {
          setTabsForManager((prevTabs) => [
            ...prevTabs,
            {
              id,
              element,
              warn,
            },
          ]);
        } else {
          setSelectedValueForManager(id);
        }
      },
      [tabs, setTabsForManager, setSelectedValueForManager]
    );
  }

  const handleOnCloseTab = useCallback((id: string) => {
    setTabs((prevTabs) => prevTabs.filter((pr) => pr.id !== id));
    if (id.includes("Bestellung:")) {
      setSelectedValue("Bestellungen");
    }
  }, []);

  const handleOpenSelectedBestellung = useCallback(
    (bestell?: Bestellungsdata) => {
      if (bestell) {
        let name = "Bestellung:" + bestell.bid;
        setTabs((prevTabs) => {
          if (prevTabs.some((tab) => tab.id.includes("Bestellung:"))) {
            setOpenDialog(true);

            return prevTabs;
          } else {
            setSelectedValue(name);
            return [
              ...prevTabs,
              {
                id: name,
                element: (
                  <BestellungForm
                    formId={name}
                    role={
                      ((bestell.stv?.toLocaleLowerCase() ===
                      me?.meInfos.upn.toLocaleLowerCase()
                        ? "Baufuehrer"
                        : undefined) ??
                        me?.meInfos.role ??
                        "None") as RoleType
                    }
                    onDismiss={handleOnCloseTab}
                    disabled={bestell.storniert === 1}
                    onOpenKopie={handleOpenSelectedBestellung}
                    {...bestell}
                  />
                ),
                warn: true,
              },
            ];
          }
        });
      } else {
        let name = "Bestellung:";
        setTabs((prevTabs) => {
          if (prevTabs.some((tab) => tab.id.includes("Bestellung:"))) {
            setOpenDialog(true);
            return prevTabs;
          } else {
            setSelectedValue(name);
            return [
              ...prevTabs,
              {
                id: name,
                element: (
                  <BestellungForm
                    role={
                      (me?.meInfos.role ?? "None") as
                        | "Admin"
                        | "Baufuehrer"
                        | "Dispo"
                        | "None"
                    }
                    onOpenKopie={handleOpenSelectedBestellung}
                    user={me?.meInfos}
                    formId={name}
                    onDismiss={handleOnCloseTab}
                  />
                ),
              },
            ];
          }
        });
      }
    },
    [handleOnCloseTab, me]
  );

  const handleOpenTab = useTabManager(tabs, setTabs, setSelectedValue);
  const handleOpenBestellungenArchiv = handleOpenTab(
    "Bestellungen Archiv",
    <BestellungArchiv
      user={me?.meInfos}
      role={(me?.meInfos.role ?? "None") as RoleType}
      onOpenBestellung={handleOpenSelectedBestellung}
    />
  );
  const handleOpenBestellungen = handleOpenTab(
    "Bestellungen",
    <BestellungenView
      user={me?.meInfos}
      role={(me?.meInfos.role ?? "None") as RoleType}
      onOpenBestellung={handleOpenSelectedBestellung}
    />
  );
  const handleOpenBelagssorten = handleOpenTab(
    "Belagssorten",
    <BelagssortenView role={(me?.meInfos.role ?? "None") as RoleType} />,
    true
  );
  const handleOpenDokumente = handleOpenTab(
    "Dokumente",
    <DokumenteView role={(me?.meInfos.role ?? "None") as RoleType} />
  );
  const handleOpenDispo = handleOpenTab(
    "Bestellungen disponieren",
    <BestellungenView
      role={"Dispo"}
      onOpenBestellung={handleOpenSelectedBestellung}
    />
  );
  const handleOpenNachrichten = handleOpenTab(
    "Nachrichten",
    <>{me?.meInfos ? <Nachrichten user={me?.meInfos} /> : null}</>
  );
  const handleOpenBaustellen = handleOpenTab(
    "Baustellen",
    <>{me?.meInfos ? <BaustellenView user={me?.meInfos} /> : null}</>,
    true
  );
  const handleOpenMitarbeiter = handleOpenTab(
    "Mitarbeiter",
    <>{me?.meInfos ? <MitarbeiterView user={me?.meInfos} /> : null}</>,
    true
  );

  const handleOpenWartung = handleOpenTab(
    "Wartungsintervalle",
    <>{me?.meInfos ? <WartungsListe user={me?.meInfos} /> : null}</>,
    true
  );

  const handleOnTabSelect = useCallback(
    (item: string) => setSelectedValue(item),
    [setSelectedValue]
  );

  const handleOpenSandwitch = useCallback(
    () => setSandwitchOpen(!sandwitchOpen),
    [sandwitchOpen]
  );
  return !loading && me?.meInfos ? (
    <div
      className={
        sandwitchOpen ? "sb-nav-fixed" : "sb-nav-fixed sb-sidenav-toggled"
      }
    >
      <Header
        user={me.meInfos}
        onToggleSandwitch={handleOpenSandwitch}
        onLogout={props.handleLogoutRedirect}
      />
      {openDialog ? (
        <FluentProvider theme={webLightTheme}>
          <Dialog open={openDialog}>
            <DialogSurface>
              <DialogBody>
                Es können nicht 2 Bestellungen gleichzeitig geöffnet sein.
              </DialogBody>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                  style={{ backgroundColor: "#B1DCE0" }}
                  appearance="primary"
                >
                  OK
                </Button>
              </DialogActions>
            </DialogSurface>
          </Dialog>
        </FluentProvider>
      ) : null}
      <div id="layoutSidenav">
        <SidebarNavigation
          user={me.meInfos}
          menuItems={[
            {
              iconName: "DeliveryTruck",
              roles: [
                "Baufuehrer",
                "GV",
                "Admin",
                "Firmen Admin",
                "Mischmeister",
              ],
              title: "Bestellungen",
              subelements: [
                {
                  roles: [
                    "Baufuehrer",
                    "Admin",
                    "GV",
                    "Firmen Admin",
                    "Mischmeister",
                  ],
                  onClick: handleOpenBestellungen,
                  subelementname: "Bestellungen Bearbeiten",
                },
                {
                  roles: [
                    "Baufuehrer",
                    "Admin",
                    "GV",
                    "Firmen Admin",
                    "Mischmeister",
                  ],
                  onClick: handleOpenBestellungenArchiv,
                  subelementname: "Bestellungen Archiv",
                },
                {
                  onClick: handleOpenBelagssorten,
                  roles: ["Admin", "GV", "Mischmeister"],

                  subelementname: "Belagssorten",
                },
                {
                  onClick: handleOpenDokumente,
                  roles: [
                    "Baufuehrer",
                    "Admin",
                    "GV",
                    "Firmen Admin",
                    "Mischmeister",
                  ],
                  subelementname: "Dokumente",
                },
              ],
            },
            {
              iconName: "DeveloperTools",
              roles: ["Admin", "Mischmeister", "GV"],
              title: "Produktion",
              subelements: [
                {
                  roles: ["Mischmeister", "Admin", "GV"],
                  onClick: handleOpenWartung,
                  subelementname: "Wartungsintervalle",
                },
              ],
            },
            {
              iconName: "FunctionalManagerDashboard",
              roles: ["Dispo", "Admin", "GV", "Firmen Admin"],
              title: "Dispo",
              subelements: [
                {
                  roles: ["Dispo", "Admin", "GV", "Firmen Admin"],
                  onClick: handleOpenDispo,
                  subelementname: "Bestellungen disponieren",
                },
              ],
            },
            {
              iconName: "Settings",
              roles: [
                "Dispo",
                "Admin",
                "Baufuehrer",
                "GV",
                "Firmen Admin",
                "Mischmeister",
              ],
              title: "Administration",
              subelements: [
                {
                  roles: ["Admin", "Mischmeister", "GV", "Firmen Admin"],
                  onClick: handleOpenNachrichten,
                  subelementname: "Nachrichten",
                },
                {
                  //GV nur view
                  roles: ["Admin", "GV", "Firmen Admin"],
                  onClick: handleOpenMitarbeiter,
                  subelementname: "Mitarbeiter",
                },
                {
                  //GV nur view
                  roles: ["Admin", "GV", "Firmen Admin"],
                  onClick: handleOpenBaustellen,
                  subelementname: "Baustellen",
                },
              ],
            },
          ]}
        />
        <div id="layoutSidenav_content">
          <main>
            <TabsView
              onTabSelect={handleOnTabSelect}
              tabs={tabs ?? []}
              selectedValue={selectedValue}
              onClose={handleOnCloseTab}
            />
          </main>
          <Footer />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
