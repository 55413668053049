import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { Checkbox } from "@fluentui/react-components";
import { BaseButton, Button, IconButton, TextField } from "@fluentui/react";

export interface IDetailsListBasicProps<T> {
  onClickRow?: (item: T & { rowid: number }) => void;
  data: Array<T & { rowid: number }>;
  columns?: IColumn[];
  noEdit?: boolean;
  showFields?: string[];
  searchable?: boolean;
  filtersTitles?: string[];
  onAdd: React.MouseEventHandler<
    | HTMLDivElement
    | HTMLAnchorElement
    | HTMLButtonElement
    | BaseButton
    | HTMLSpanElement
  >;
  onRemoveClick: (selecteditems: Array<T & { rowid: number }>) => void;
  hasSave?: boolean;
  onSaveClick?: () => void;
  height?: string | number | undefined;
  disabled?:boolean;
}

function capitalize(s: string) {
  return s[0].toUpperCase() + s.slice(1);
}

export function DetailsListEditable<T>(props: IDetailsListBasicProps<T>) {
  const [searchQuery, setSearchQuery] = useState<string>(""); // Search query state
  const [items, setItems] = useState<Array<T & { rowid: number }>>(props.data);
  const [selecteditems, setSelecteditems] = useState<
    Array<T & { rowid: number }>
  >(new Array<T & { rowid: number }>());

  const onRenderCheckbox = useCallback(
    (
      item?: T & { rowid: number },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      let checked =
        selecteditems.find((pr) => {
          const tmp = props.searchable
            ? props.data.find((pr2) => pr2.rowid === item?.rowid)
            : items.find((pr2, i) => i === index);
          return tmp?.rowid === pr.rowid;
        }) !== undefined;
      return (
        <Checkbox
          checked={checked}
          onClick={() => {
            checked = !checked;
            if (checked) {
              setSelecteditems([
                ...selecteditems,
                item as T & {
                  rowid: number;
                },
              ]);
            } else {
              setSelecteditems([
                ...selecteditems.filter((pr) => pr.rowid !== item?.rowid),
              ]);
            }
          }}
        />
      );
    },
    [selecteditems, props.data,props.searchable,items]
  );

  const CheckboxColumn = useMemo(
    () => [
      {
        key: "checkbox",
        name: "",
        maxWidth: 100,
        isResizable: false,
        onRender: onRenderCheckbox,
      } as IColumn,
    ],
    [onRenderCheckbox]
  );

  const filteredItems = useMemo(() => {
    if (!searchQuery || !props.searchable) return props.data;
    return props.data.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [props.data, searchQuery,props]);

  const defaultColumns = useMemo(() => {
    const keys = Object.keys(props.data[0] ?? {});

    if (props.showFields) {
      return CheckboxColumn.concat(
        props.showFields
          .filter((pr) => keys.includes(pr))
          .map<IColumn>((k) => ({
            key: k,
            name: capitalize(k),
            fieldName: k,
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          }))
      );
    } else if (props.noEdit) {
      return keys.map<IColumn>((k) => ({
        key: k,
        name: k,
        fieldName: k,
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      }));
    } else {
      return CheckboxColumn.concat(
        keys.map<IColumn>((k) => ({
          key: k,
          name: k,
          fieldName: k,
          minWidth: 100,
          maxWidth: 200,
          isResizable: true,
        }))
      );
    }
  }, [props.data, props.showFields, CheckboxColumn, props.noEdit]);

  const filtertext = useMemo(
    () =>
      props.filtersTitles
        ? `Filter für Tabellen-Inhalte wie: ${props.filtersTitles?.reduce(
            (prev, cur) => prev + "," + cur
          )}`
        : "",
    [props.filtersTitles]
  );

  const onItemInvoked = (
    item: T & {
      rowid: number;
    }
  ): void => {
    if (props.onClickRow) {
      props.onClickRow(item);
    }
  };

  const handeRemove = useCallback<
    React.MouseEventHandler<
      | HTMLDivElement
      | HTMLAnchorElement
      | HTMLButtonElement
      | BaseButton
      | HTMLSpanElement
    >
  >(
    (e) => {
      if (selecteditems.length > 0) {
        props.onRemoveClick(selecteditems);
      }
    },
    [props, selecteditems]
  );
  const handleSaveClick = useCallback(
    (
      event: React.MouseEvent<
        | HTMLDivElement
        | HTMLAnchorElement
        | HTMLButtonElement
        | BaseButton
        | HTMLSpanElement
        | Button,
        MouseEvent
      >
    ) => {
      if (props.onSaveClick) {
        props.onSaveClick();
      }
    },
    [props]
  );
  useEffect(() => {
    setItems(props.data);
  }, [props.data]);

  return (
    <div className="belagsliste">
      {props.noEdit ? (
        <></>
      ) : (
        <>
          <IconButton
            onClick={props.onAdd}
            title="Hinzufügen"
            ariaLabel="Hinzufügen"
            disabled={props.disabled}
            iconProps={{
              style: { color: "black" },
              iconName: "CircleAdditionSolid",
            }}
          />
          <IconButton
            disabled={props.disabled}
            onClick={handeRemove}
            title="Löschen"
            ariaLabel="Löschen"
            iconProps={{ style: { color: "black" }, iconName: "Blocked2Solid" }}
          />

          <IconButton
            disabled={props.disabled}
            hidden={!props.hasSave}
            iconProps={{ style: { color: "black" }, iconName: "Save" }}
            title={"Speichern"}
            ariaLabel={"Speichern"}
            onClick={handleSaveClick}
          />
        </>
      )}

      {props.searchable ? (
        <TextField
          label="Filtern"
          value={searchQuery}
          onChange={(e, newValue) => setSearchQuery(newValue || "")}
          placeholder={filtertext}
          styles={{ root: { marginBottom: "10px" } }}
        />
      ) : null}
      <DetailsList
        items={props.searchable ? filteredItems : items}
        selectionMode={SelectionMode.none}
        styles={props.height ? { root: { height: props.height } } : undefined}
        columns={
          props.columns
            ? props.noEdit
              ? props.columns
              : CheckboxColumn.concat(props.columns)
            : defaultColumns
        }
        setKey="set"
        layoutMode={DetailsListLayoutMode.justified}
        onItemInvoked={onItemInvoked}
      />
    </div>
  );
}

export default DetailsListEditable;
