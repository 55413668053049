import React, { useEffect } from "react";
import { PDFViewer } from "../components/pdf/PDFViewer";
import { useGetDokumentPdfLazyQuery } from "../gql/graphql";


export function DokumentPDFView(props: {
  onDismiss: (
    ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
  show: boolean;
  path: string;
}) {
  const [getPDF, { loading, data  }] =
    useGetDokumentPdfLazyQuery();


  function byteToUint8Array(byteArray: any[]) {
    var uint8Array = new Uint8Array(byteArray.length);
    for (var i = 0; i < uint8Array.length; i++) {
      uint8Array[i] = byteArray[i];
    }
    return uint8Array;
  }
  useEffect(() => {
    if (props.path && !data && props.show) {
      getPDF({ variables: { path: props.path } });
    } 
  }, [getPDF,  data, props]);
  if (props.show && !loading && data?.dokumentPdf) {
    return (
      <PDFViewer
        show={props.show}
        pdfBytes={byteToUint8Array(data?.dokumentPdf as [])}
        onDismiss={props.onDismiss}
        downloadFileName={props.path}
      />
    );
  }  else {
    return <></>;
  }
}
