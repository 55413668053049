import {
  ContextualMenu,
  Dialog,
  IconButton,
  PrimaryButton,
  ScrollablePane,
} from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { OnDocumentLoadSuccess } from "react-pdf/dist/cjs/shared/types";

const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
};

export function isChromiumBased() {
  const userAgent = navigator.userAgent;

  if (
    userAgent.includes("Chrome") ||
    userAgent.includes("Chromium") ||
    userAgent.includes("Edg") ||
    userAgent.includes("Brave")
  ) {
    return true;
  } else {
    return false;
  }
}

export function PDFViewer(props: {
  pdfBytes: Uint8Array;
  show?: boolean;
  onDismiss: (
    ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
  downloadFileName?: string;
}) {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [dwldata, setDwldata] = useState<Uint8Array>();

  const handloLoadSuccess = useCallback<OnDocumentLoadSuccess>(async (dta) => {
    setNumPages(dta.numPages);
    setDwldata(await dta.saveDocument());
  }, []);
  const options = useMemo(
    () => ({
      standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
    }),
    []
  );

  const modalProps = useMemo(
    () => ({
      isBlocking: true,
      dragOptions: dragOptions,
    }),
    []
  );
  const file = useMemo(() => ({ data: props.pdfBytes }), [props]);
  const download = useCallback(() => {
    if (dwldata && dwldata?.byteLength > 0) {
      const blob = new Blob([dwldata], {
        type: "application/pdf",
      });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = props.downloadFileName
        ? props.downloadFileName
        : Date.now() + "_belagstool_download.pdf";
      link.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(url);
    }
  }, [dwldata]);
  const print = useCallback(() => {
    if (dwldata && dwldata?.byteLength > 0) {
      const blob = new Blob([dwldata], {
        type: "application/pdf",
      });
      const url = URL.createObjectURL(blob);
      const printWindow = window.open(url);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
          URL.revokeObjectURL(url); // Clean up the object URL after printing
        };
      } else {
        alert("Pop-up blocker may be preventing the PDF from opening.");
      }
    }
  }, [dwldata]);
  if (process.env.NODE_ENV === "development") {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      "pdfjs-dist/build/pdf.worker.min.mjs",
      import.meta.url
    ).toString();
  } else {
    pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.mjs`;
  }
  const printnochrome = useCallback(() => {
    if (props.pdfBytes && props.pdfBytes?.byteLength > 0) {
      const blob = new Blob([props.pdfBytes], {
        type: "application/pdf",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = props.downloadFileName
        ? props.downloadFileName
        : Date.now() + "_belagstool_download.pdf";
      link.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(url);
    }
  }, [props]);

  useEffect(() => {
    if (isChromiumBased() === false) {
      printnochrome();
      props.onDismiss();
    }
  }, [printnochrome, props]);

  return props.show && isChromiumBased() ? (
    <>
      <Dialog
        onDismiss={props.onDismiss}
        modalProps={modalProps}
        hidden={!props.show}
        minWidth={"40vw"}
      >
        <div className="cont" style={{ display: "flex" }}>
          <div className="left-inner" style={{ width: "30%" }}>
            <IconButton
              iconProps={{ iconName: "DownloadDocument" }}
              title={"Download"}
              ariaLabel={"Download"}
              onClick={download}
              style={{
                paddingRight: 20,
                paddingLeft: 20,
                border: "solid black 1px",
                color: "#B1DCE0",
              }}
            />
            <IconButton
              iconProps={{ iconName: "Print" }}
              title={"Drucken"}
              ariaLabel={"Drucken"}
              onClick={print}
              style={{
                paddingRight: 20,
                paddingLeft: 20,
                marginLeft: 5,
                border: "solid black 1px",
                color: "#B1DCE0",
              }}
            />
          </div>
          <div
            className="dialog-inner"
            style={{ height: "80vh", width: "70%" }}
          >
            <ScrollablePane style={{ marginTop: 35, alignItems: "center" }}>
              <Document
                onLoadError={console.error}
                file={file}
                options={options}
                onLoadSuccess={handloLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </ScrollablePane>
            <div
              className="navDialogWrapper"
              style={{ display: "flex", width: "100%" }}
            >
              <div
                className="left"
                style={{ width: "50%", alignContent: "left" }}
              >
                {pageNumber !== 1 ? (
                  <PrimaryButton
                    style={{ position: "absolute", left: 0, marginLeft: "6%" }}
                    onClick={() => setPageNumber(pageNumber - 1)}
                  >
                    Back
                  </PrimaryButton>
                ) : null}
              </div>

              <div className="right" style={{ width: "50%" }}>
                {pageNumber < numPages ? (
                  <PrimaryButton
                    style={{
                      position: "absolute",
                      right: 0,
                      marginRight: "3%",
                    }}
                    onClick={() => setPageNumber(pageNumber + 1)}
                  >
                    Next
                  </PrimaryButton>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <br />
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </Dialog>
    </>
  ) : (
    <></>
  );
}
