import React, { useState } from "react";
import { User } from "../gql/graphql";
import { RoleType } from "./Content";
import { Icon } from "@fluentui/react";
export interface ISidebarNavigationProps {
  user: User;
  menuItems: IMenuItemProps[];
}

export interface IMenuItemProps {
  title: string;
  subelements?: Array<{
    onClick: () => void;
    subelementname: string;
    roles: RoleType[];
  }>;
  onClick?: () => void;
  roles: RoleType[];
  iconName?: string;
}
export function SidebarNavigation(props: ISidebarNavigationProps) {
  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-light"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <div className="nav">
            <div className="sb-sidenav-menu-heading">Belagstool</div>

            {props.menuItems.map((pr) =>
              pr.roles.includes(props.user.role as RoleType) ||
              props.user.role === "Admin" ? (
                <MenuItem
                  title={pr.title}
                  subelements={pr.subelements}
                  user={props.user}
                  iconName={pr.iconName}
                />
              ) : null
            )}
          </div>
        </div>
        <div className="sb-sidenav-footer">
          <div className="small">Support:</div>
          support@tozzo.ch
        </div>
      </nav>
    </div>
  );
}

function MenuItem(props: {
  title: string;
  iconName?: string;
  subelements?: Array<{
    onClick: () => void;
    subelementname: string;
    roles: RoleType[];
  }>;
  onClick?: () => void;
  user: User;
}) {
  const [show, setShow] = useState("");
  return (
    <>
      <li
        role="button"
        className={show !== "" ? "nav-link" : "nav-link collapsed"}
        onClick={() => {
          if (show !== "") {
            setShow("");
          } else {
            setShow("show");
          }
        }}
        data-bs-toggle="collapse"
        data-bs-target="#collapseLayouts"
        aria-expanded={show !== ""}
        aria-controls="collapseLayouts"
      >
        <div className="sb-nav-link-icon">
          {props.iconName ? (
            <Icon iconName={props.iconName} />
          ) : (
            <i className="fas fa-columns"></i>
          )}
        </div>
        {props.title}
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down"></i>
        </div>
      </li>
      {props.subelements ? (
        <div
          className={"collapse " + show}
          id="collapseLayouts"
          aria-labelledby="headingOne"
          data-bs-parent="#sidenavAccordion"
          key={"mi" + Date.now()}
        >
          <nav className="sb-sidenav-menu-nested nav">
            {props.subelements
              .filter((pr) =>
                pr.roles.includes(
                  props.user.role as "Admin" | "Baufuehrer" | "Dispo"
                )
              )
              .map((pr) => (
                <li className="nav-link" onClick={pr.onClick}>
                  {pr.subelementname}
                </li>
              ))}
          </nav>
        </div>
      ) : null}
    </>
  );
}
