import React, { useState } from "react";
import { TextField } from "@fluentui/react";
import { Field } from "@fluentui/react-components";

interface EMailTextFieldProps {
  label?: string;
  required?: boolean;
  onChange?: (email: string) => void;
  disabled?: boolean;
  text?:string,
}

const EMailTextField: React.FC<EMailTextFieldProps> = ({
  label = "Email",
  required = false,
text,
  onChange,
  disabled,
}) => {
  const [email, setEmail] = useState(text??"");
  const [error, setError] = useState<string | undefined>(undefined);

  // Basic email validation pattern
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    const emailValue = newValue || "";
    setEmail(emailValue);

    // Validate email format
    if (emailValue && !emailPattern.test(emailValue)) {
      setError("Please enter a valid email address.");
    } else {
      setError(undefined);
    }

    // Pass email back to parent if onChange prop is provided
    if (onChange) {
      onChange(emailValue);
    }
  };

  return (
    <Field label={label} style={{ marginTop: 10 }} orientation="horizontal">
      <TextField
        required={required}
        value={email}
        translate="no"
        onChange={handleChange}
        disabled={disabled}
        errorMessage={error}
        type="email"
      />
    </Field>
  );
};

export default EMailTextField;
