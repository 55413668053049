import React, { useCallback } from "react";
import { TextField } from "@fluentui/react";

export function FloatNumberTextBox(props: {
  onBlur?: () => void;
  label?: string;
  value: string;
  disabled?: boolean;
  showWarning?: boolean;
  errorMessage?: string;
  onChange: (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => void;
}) {
  const handleChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      // Ensure the input is a valid float number
      if (newValue === "" || /[+-]?([0-9]*[.])?[0-9]+/.test(newValue ?? "")) {
        props.onChange(event, newValue);
      }
    },
    [props]
  );

  return (
    <TextField
      label={props.label}
      value={props.value && props.value !== null ? props.value : "0"}
      step={0.1}
      disabled={props.disabled}
      onChange={handleChange}
      onBlur={props.onBlur}
      type="number"
      onGetErrorMessage={
        props.showWarning
          ? (str) => {
              return props.errorMessage
                ? props.errorMessage
                : "Feld braucht einen Wert.";
            }
          : undefined
      }
      validateOnLoad={props.showWarning} // Optional: prevent validation when the component first loads
      validateOnFocusOut={props.showWarning} // Optional: validate when the field loses focus
      validateOnFocusIn={props.showWarning}
    />
  );
}
