import React, { useCallback, useMemo, useState } from "react";
import { durationToTime } from "../bestellung/hooks/useInitializeFormData";
import { IComboBox, TimePicker } from "@fluentui/react";

export function ZeitColumn(props: {
  anchor: Date;
  onChange: (time: string) => void;
  value?: string;
  disabled?:boolean;
  nonEditable?: boolean;
  increments?: number;
}) {
  const [show, setShow] = useState(false);
  const changeShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  const handleChange = useCallback(
    (event: React.FormEvent<IComboBox>, time: Date) => {
      let Hourstringtmp =
        time.getHours() >= 1
          ? time.getHours() >= 10
            ? time.getHours() + ""
            : "0" + time.getHours()
          : "00";
      let Minutestringtmp =
        time.getMinutes() >= 1
          ? time.getMinutes() >= 10
            ? time.getMinutes() + ""
            : time.getMinutes() + "0"
          : "00";
      props.onChange(Hourstringtmp + ":" + Minutestringtmp);
    },
    [props]
  );

  const value = useMemo(() => {
    if (props.value) {
      let tmpDate = props.anchor;
      if (String(props.value).includes(":") === false) {
        let HoureMinute = durationToTime(props.value).split(":");
        tmpDate.setHours(Number(HoureMinute[0]), Number(HoureMinute[1]));
      } else {
        let HoureMinute = String(props.value).split(":");
        tmpDate.setHours(Number(HoureMinute[0]), Number(HoureMinute[1]));
      }

      return tmpDate;
    }
  }, [props]);
  let Hourstring = value
    ? value?.getHours() >= 1
      ? value?.getHours() >= 10
        ? value?.getHours() + ""
        : "0" + value?.getHours()
      : "00"
    : "00";
  let Minutestring = value
    ? value?.getMinutes() >= 1
      ? value?.getMinutes() >= 10
        ? value?.getMinutes() + ""
        : value?.getMinutes() + "0"
      : "00"
    : "00";
  return !props.nonEditable && show ? (
    <TimePicker
      placeholder="Bsp. 08:00"
      increments={props.increments ?? 10}
      allowFreeform
      disabled={props.disabled}
      autoComplete="on"
      onBlur={() => setShow(!show)}
      dateAnchor={props.anchor}
      value={value}
      onChange={handleChange}
    />
  ) : (
    <div style={{ width: "100%", height: "100%" }} onClick={changeShow}>
      {Hourstring + ":" + Minutestring}
    </div>
  );
}
