import { ComboBox, IComboBox, IComboBoxOption } from "@fluentui/react";
import { TypingsOptions } from "../gql/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface IColumninnerComboProps<T> {
  disabled?: boolean;
  onChange: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    index?: number | undefined,
    value?: string | undefined
  ) => void;
  optionsCreator?: (pr: TypingsOptions) => IComboBoxOption;
  item?: T;
  data: TypingsOptions[];
  field: keyof T;
  optionskeyfield?: keyof T;
  showError?: boolean;
  errorMessage?: string;
}

export function ComboColumn<T>(props: IColumninnerComboProps<T>) {
  const [show, setShow] = useState(false);
  const [item, setItem] = useState(props.item);
  useEffect(() => {
    if (props.item !== item) {
      setItem(props.item);
    }
  }, [item, props.item]);
  const { onChange } = props;

  const changeShow = useCallback(() => {
    setShow((prevShow) => !prevShow);
  }, []);

  const valueWithKey = useMemo(() => {
    let listtmp = props.data.map<IComboBoxOption>((pr) => ({
      key: pr.option_nr ?? "",
      text: pr.option_value ?? "",
    }));
    let selkey =
      item && props.optionskeyfield ? String(item[props.optionskeyfield]) : "";
    return listtmp.find((pr) => pr.key + "" === selkey)?.text ?? "";
  }, [props.data, item, props.optionskeyfield]);
  return show ? (
    props.optionskeyfield ? (
      <ComboBox
        translate="no"
        text={valueWithKey}
        autoComplete="on"
        disabled={props.disabled}
        onBlur={changeShow}
        errorMessage={
          props.showError
            ? props.errorMessage ?? "Youre Error Message is Missing"
            : undefined
        }
        onChange={onChange}
        options={props.data.map<IComboBoxOption>(
          props.optionsCreator ??
            ((pr) => ({
              key: pr.option_nr ?? "",
              text: pr.option_value ?? "",
            }))
        )}
        defaultValue={valueWithKey}
        selectedKey={item ? (item[props.optionskeyfield] as string) ?? "" : ""}
      />
    ) : (
      <ComboBox
        text={item ? (item[props.field] as string) ?? " " : " "}
        autoComplete="on"
        translate="no"
        disabled={props.disabled}
        onBlur={changeShow}
        errorMessage={
          props.showError
            ? props.errorMessage ?? "Youre Error Message is Missing"
            : undefined
        }
        onChange={onChange}
        options={props.data.map<IComboBoxOption>(
          props.optionsCreator ??
            ((pr) => ({
              key: pr.option_value ?? "",
              text: pr.option_value ?? "",
            }))
        )}
        defaultValue={item ? (item[props.field] as string) ?? " " : " "}
        selectedKey={item ? (item[props.field] as string) ?? "" : ""}
      />
    )
  ) : (
    <div
      translate="no"
      style={{ width: "100%", height: "100%" }}
      onClick={changeShow}
    >
      <p>
        {props.optionskeyfield
          ? valueWithKey
          : item
          ? (item[props.field] as string) ?? " "
          : " "}
      </p>
      <p style={{ color: "red", textWrap: "initial" }}>
        {props.showError
          ? props.errorMessage ?? "Youre Error Message is Missing"
          : undefined}{" "}
      </p>
    </div>
  );
}
