import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Page } from "../../components/Page";
import {
  IColumn,
  IComboBox,
  IComboBoxOption,
  IconButton,
  ContextualMenu,
  Dialog,
  PrimaryButton,
} from "@fluentui/react";
import {
  Belag,
  BelagInput,
  GetBelagDocument,
  useGetBelagOptionsQuery,
  useGetBelagQuery,
  useSaveBelagMutation,
  useUploadBelagPdfMutation,
} from "../../gql/graphql";
import DetailsListEditable from "../../components/DetailsListEditable";
import { ComboColumn } from "../../components/ComboColumn";
import { BelagsSortePDFView } from "./BelagsSortePDFView";
import { RoleType } from "../../components/Content";
import { Field } from "@fluentui/react-components";

interface IBelagsListeProps {
  role: RoleType;
}

export function BelagssortenView(props: IBelagsListeProps) {
  const { data: belagoptions, loading: loadingOptions } =
    useGetBelagOptionsQuery();
  const [rcwerte, setRcwerte] = useState(belagoptions?.Recpercent);
  const [belagssorten, setBelagssorten] = useState(belagoptions?.Belagssorte);
  const [typs, setTyps] = useState(belagoptions?.BSTyp);
  const [binder, setBinder] = useState(belagoptions?.Binder);
  const [zusatz, setZusatz] = useState(belagoptions?.BSZusatz);
  const [openUploadDialogBelagID, setOpenUploadDialogBelagID] =
    useState<number>();
  const [filename, setFilename] = useState<string>();
  const [file, setFile] = useState<string | undefined>();

  const [pdf, setPdf] = useState<string | undefined>();
  const { loading, data } = useGetBelagQuery();
  const [save] = useSaveBelagMutation({
    refetchQueries: () => [{ query: GetBelagDocument }],
  });
  const [upload] = useUploadBelagPdfMutation({
    refetchQueries: (a) => [{ query: GetBelagDocument }],
  });
  const [belagdata, setBelagdata] = useState<
    | (Partial<Belag> & {
        rowid: number;
        edited: boolean;
        removed: boolean;
      })[]
    | undefined
  >();

  useEffect(() => {
    if (
      !loading &&
      data &&
      !belagdata &&
      !loadingOptions &&
      belagoptions !== null &&
      belagoptions
    ) {
      setRcwerte(belagoptions?.Recpercent ?? []);
      setBelagssorten(belagoptions.Belagssorte);
      setTyps(belagoptions?.BSTyp);
      setBinder(belagoptions?.Binder);
      setZusatz(belagoptions.BSZusatz);

      let elements = new Set(data?.belag.length > 0 ? data?.belag : []);
      let arr = Array.from(elements).sort((a, b) => {
        let curr = a.id ?? 0;
        let prev = b.id ?? 0;
        return prev - curr;
      });
      setBelagdata(
        arr
          .map((pr, i) => ({
            ...pr,
            rowid: i,
            removed: false,
            edited: false,
          }))
          .sort((a, b) => a.rowid - b.rowid)
      );
    }
  }, [
    belagoptions?.Recpercent,
    belagdata,
    loading,
    data,
    belagoptions,
    loadingOptions,
  ]);
  const handleAddDoc = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (e?.target?.files) {
        const fileloaded = e?.target?.files[0];
        setFilename(fileloaded.name);
        const reader = new FileReader();

        reader.onloadend = function () {
          const base64String = reader.result as string;

          setFile(base64String);
        };
        reader.readAsDataURL(fileloaded);
      }
    },
    [setFile, setFilename]
  );
  function useHandleOfBelag(
    key: keyof (Partial<Belag> & { edited: boolean; removed: boolean }),
    isNumeric?: boolean,
    isBoolean?: boolean
  ) {
    return useCallback(
      (belag?: Belag & { rowid: number }, val?: string, index?: number) => {
        if (index !== undefined) {
          setBelagdata((prevBelagsliste) =>
            prevBelagsliste?.map((item, i) => {
              if (item?.rowid === index) {
                return {
                  ...item,
                  [key]: isNumeric
                    ? Number(val)
                    : isBoolean
                    ? val === "true"
                    : val,
                };
              }
              return item;
            })
          );
        }
      },
      [key, isNumeric, isBoolean]
    );
  }

  const onRecpercentChange = useHandleOfBelag("bSrcp");
  const onBelagssorteChange = useHandleOfBelag("bSsorte");
  const onTypChange = useHandleOfBelag("bStyp");
  const onBinderChange = useHandleOfBelag("bSbinder");
  const onZusatzChange = useHandleOfBelag("bSzusatz");
  const handleStateChange = useHandleOfBelag("edited", false, true);
  const onRenderBelagssorte = useCallback(
    (item?: Belag & { rowid: number }, index?: number, column?: IColumn) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        i?: number,
        value?: string
      ) => {
        onBelagssorteChange(item, String(option?.text), item?.rowid);
        handleStateChange(item, "true", item?.rowid);
      };

      return (
        <ComboColumn<Belag>
          data={belagssorten ?? []}
          field="bSsorte"
          onChange={onChange}
          optionsCreator={(pr) => ({
            key: pr.option_value ?? "",
            text: pr.option_value ?? "",
          })}
          item={item}
        />
      );
    },
    [onBelagssorteChange, belagssorten, handleStateChange]
  );

  const onRenderTyp = useCallback(
    (
      item?: Belag & { rowid: number },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        i?: number | undefined,
        value?: string | undefined
      ) => {
        onTypChange(item, String(option?.text), item?.rowid);
        handleStateChange(item, "true", item?.rowid);
      };

      return (
        <ComboColumn<Belag>
          data={typs ?? []}
          field="bStyp"
          onChange={onChange}
          optionsCreator={(pr) => ({
            key: pr.option_value ?? "",
            text: pr.option_value ?? "",
          })}
          item={item}
        />
      );
    },
    [onTypChange, typs, handleStateChange]
  );

  const onRenderBinder = useCallback(
    (
      item?: Belag & { rowid: number },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        i?: number | undefined,
        value?: string | undefined
      ) => {
        onBinderChange(item, String(option?.text), item?.rowid);
        handleStateChange(item, "true", item?.rowid);
      };

      return (
        <ComboColumn<Belag>
          data={binder ?? []}
          field="bSbinder"
          onChange={onChange}
          optionsCreator={(pr) => ({
            key: pr.option_value ?? "",
            text: pr.option_value ?? "",
          })}
          item={item}
        />
      );
    },
    [onBinderChange, binder, handleStateChange]
  );

  const onRenderZusatz = useCallback(
    (
      item?: Belag & { rowid: number },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        i?: number | undefined,
        value?: string | undefined
      ) => {
        onZusatzChange(item, String(option?.text), item?.rowid);
        handleStateChange(item, "true", item?.rowid);
      };

      return (
        <ComboColumn<Belag>
          data={zusatz ?? []}
          field="bSzusatz"
          onChange={onChange}
          optionsCreator={(pr) => ({
            key: pr.option_value ?? "",
            text: pr.option_value ?? "",
          })}
          item={item}
        />
      );
    },
    [onZusatzChange, zusatz, handleStateChange]
  );
  const onRenderRecpercent = useCallback(
    (
      item?: Belag & { rowid: number },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        i?: number | undefined,
        value?: string | undefined
      ) => {
        onRecpercentChange(item, String(option?.text), item?.rowid);
        handleStateChange(item, "true", item?.rowid);
      };
      return (
        <ComboColumn<Belag>
          data={rcwerte ?? []}
          field="bSrcp"
          onChange={onChange}
          optionsCreator={(pr) => ({
            key: pr.option_value ?? "",
            text: pr.option_value?.replace("RC ", "") ?? "",
          })}
          item={item}
        />
      );
    },
    [onRecpercentChange, rcwerte, handleStateChange]
  );

  const onRenderErstPruefFile = useCallback(
    (
      item?: Belag,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      if (item?.erstPruefFile && item?.erstPruefFile !== null && pdf) {
        return (
          <div className="prueffilewrap">
            <IconButton
              style={{ color: "#B1DCE0" }}
              iconProps={{ iconName: "PDF" }}
              title={item.erstPruefFile
                .replaceAll("./", "")
                .replaceAll("/", "")}
              ariaLabel={item.erstPruefFile
                .replaceAll("./", "")
                .replaceAll("/", "")}
              onClick={() => {
                if (item.erstPruefFile && item.erstPruefFile !== null) {
                  setPdf(
                    item.erstPruefFile.replaceAll("./", "").replaceAll("/", "")
                  );
                }
              }}
            />

            <BelagsSortePDFView
              show={!!pdf && pdf === item.erstPruefFile}
              id={item.id as number}
              onDismiss={() => setPdf(undefined)}
              path={pdf}
            />
          </div>
        );
      } else if (item?.erstPruefFile && item?.erstPruefFile !== null) {
        return (
          <div className="prueffilewrap">
            <IconButton
              style={{ color: "#B1DCE0" }}
              iconProps={{ iconName: "PDF" }}
              title={item.erstPruefFile
                .replaceAll("./", "")
                .replaceAll("/", "")}
              ariaLabel={item.erstPruefFile
                .replaceAll("./", "")
                .replaceAll("/", "")}
              onClick={() => {
                if (item.erstPruefFile && item.erstPruefFile !== null) {
                  setPdf(
                    item.erstPruefFile.replaceAll("./", "").replaceAll("/", "")
                  );
                }
              }}
            />
          </div>
        );
      } else {
        return <p></p>;
      }
    },
    [pdf]
  );

  const belagcolumns: IColumn[] = useMemo(
    () => [
      {
        key: "belagssorte",
        name: "Belagssorte",
        fieldName: "belagssorte",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderBelagssorte,
      },
      {
        key: "typ",
        name: "Typ",
        fieldName: "typ",
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        onRender: onRenderTyp,
      },
      {
        key: "bindemittel",
        name: "Bindemittel",
        fieldName: "bindemittel",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderBinder,
      },
      {
        key: "zusatz",
        name: "Zusatz",
        fieldName: "zusatz",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderZusatz,
      },
      {
        key: "rc_Anteil",
        name: "RC Anteil",
        fieldName: "rc_Anteil",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        onRender: onRenderRecpercent,
      },
      {
        key: "erstPruefFile",
        name: "Erstpruef Datei",
        fieldName: "erstPruefFile",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderErstPruefFile,
      },
      {
        key: "inaktiv",
        name: "Inaktiv",
        fieldName: "inaktiv",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
      },
      {
        key: "",
        name: "",
        fieldName: "",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        onRender: (item?: Belag, index?: number, column?: IColumn) => {
          if (item?.id !== null && item?.id !== undefined) {
            return (
              <IconButton
                iconProps={{ iconName: "Upload", color: "#B1DCE0" }}
                ariaLabel="Upload Erstpruef Datei"
                title="Upload Erstpruef Datei"
                style={{ color: "#B1DCE0" }}
                onClick={() => {
                  setOpenUploadDialogBelagID(
                    item.id !== null ? item.id : undefined
                  );
                }}
              />
            );
          } else {
            return <></>;
          }
        },
      },
    ],
    [
      onRenderBelagssorte,
      onRenderErstPruefFile,
      onRenderRecpercent,
      onRenderTyp,
      onRenderZusatz,
      onRenderBinder,
    ]
  );
  const dragOptions = useMemo(
    () => ({
      moveMenuItemText: "Move",
      closeMenuItemText: "Close",
      menu: ContextualMenu,
    }),
    []
  );
  return (
    <Page scroll title="Belagssorten">
      <div className="belagssortenView">
        <Dialog
          onDismiss={() => setOpenUploadDialogBelagID(undefined)}
          modalProps={{ dragOptions, isBlocking: true }}
          minWidth={"40vw"}
          hidden={
            openUploadDialogBelagID === null ||
            openUploadDialogBelagID === undefined
          }
        >
          <Field
            style={{ paddingTop: 10 }}
            orientation="horizontal"
            label="File"
          >
            <input type="file" onChange={handleAddDoc} />
          </Field>
          <Field style={{ paddingTop: 10 }} orientation="horizontal"></Field>
          <PrimaryButton
            disabled={
              !(
                openUploadDialogBelagID !== null &&
                openUploadDialogBelagID !== undefined &&
                filename !== undefined &&
                file !== undefined
              )
            }
            onClick={() => {
              if (
                openUploadDialogBelagID !== null &&
                openUploadDialogBelagID !== undefined &&
                !!filename &&
                !!file
              ) {
                upload({
                  variables: { file, filename, id: openUploadDialogBelagID },
                  onCompleted: (uploaddata, clientOptions) => {
                    setBelagdata(
                      belagdata?.map((pr) =>
                        pr.id === openUploadDialogBelagID
                          ? {
                              ...pr,
                              erstPruefFile: uploaddata.belag.uploadPruefFile,
                            }
                          : pr
                      )
                    );
                    setFile(undefined);
                    setFilename(undefined);
                    setOpenUploadDialogBelagID(undefined);
                  },
                });
              }
            }}
          >
            Hochladen
          </PrimaryButton>
        </Dialog>
        {!loading ? (
          <DetailsListEditable<Partial<Belag> & { rowid: number }>
            noEdit={
              !["Admin", "Firmen Admin", "GV", "Mischmeister"].includes(
                props.role
              )
            }
            hasSave
            searchable
            filtersTitles={["Bindemittel, Belagssorte und Zusatz"]}
            onSaveClick={() => {
              let updatables =
                belagdata
                  ?.filter((pr) => pr.edited && pr.removed === false)
                  .filter(
                    (valobj) =>
                      valobj.firma !== undefined &&
                      (valobj.bSsorte ?? "").length > 0 &&
                      (valobj.bSbinder ?? "").length > 0 &&
                      valobj.bm !== undefined &&
                      valobj.id !== null &&
                      valobj.id !== undefined
                  )
                  .map<BelagInput>((pr) => {
                    let {
                      removed,
                      edited,
                      rowid,
                      ersteller,
                      ersteller_nr,
                      eingetragen,
                      __typename,
                      ...innerpr
                    } = pr;
                    return {
                      ...innerpr,
                      firma: Number(pr.firma) ?? 0,
                      sorte: pr.bSsorte ?? "",
                      bindemittel: pr.bSbinder ?? "",
                      bm: pr.bm ?? 0,
                      id: pr.id,
                    };
                  }) ?? [];
              let removable =
                belagdata
                  ?.filter(
                    (pr) => pr.removed && pr.id !== undefined && pr.id !== null
                  )
                  .map((pr) => pr.id ?? 0) ?? [];

              let addable =
                belagdata
                  ?.filter((pr) => pr.id === null || pr.id === undefined)
                  .filter(
                    (valobj) =>
                      valobj.firma !== undefined &&
                      (valobj.bSsorte ?? "").length > 0 &&
                      (valobj.bSbinder ?? "").length > 0 &&
                      valobj.bm !== undefined
                  )
                  .map<BelagInput>((pr) => {
                    let {
                      removed,
                      edited,
                      rowid,
                      ersteller,
                      ersteller_nr,
                      eingetragen,
                      __typename,
                      ...innerpr
                    } = pr;
                    return {
                      ...innerpr,
                      firma: Number(pr.firma) ?? 0,
                      sorte: pr.bSsorte ?? "",
                      bindemittel: pr.bSbinder ?? "",
                      bm: pr.bm ?? 0,
                    };
                  }) ?? [];
              if (
                updatables.length > 0 ||
                addable.length > 0 ||
                removable.length > 0
              ) {
                save({
                  variables: {
                    addbelag: addable,
                    updatebelag: updatables,
                    delBelag: removable,
                  },
                });
              }
            }}
            data={belagdata?.filter((pr) => pr.removed !== true) ?? []}
            onAdd={() => {
              let tmp = belagdata?.map((pr) => pr.rowid) ?? [];
              let wart: Partial<Belag> & {
                rowid: number;
                edited: boolean;
                removed: boolean;
              } = {
                firma: 1,
                bSsorte: "",
                bSbinder: "",
                bm: 0,
                rowid: Math.max(...tmp) + 1,
                removed: false,
                edited: false,
              };
              let tmpsorted = [wart, ...(belagdata ?? [])];
              setBelagdata(tmpsorted);
            }}
            onRemoveClick={(selected) => {
              let newbaustellenrarr = belagdata?.filter(
                (pr) =>
                  selected?.map((t) => t.rowid).includes(pr.rowid) === false
              );
              newbaustellenrarr = [
                ...(newbaustellenrarr ?? []),
                ...selected
                  .filter((a) => a.id !== null && a.id !== undefined)
                  .map((k) => ({ ...k, removed: true, edited: false })),
              ];
              if (newbaustellenrarr) {
                setBelagdata([...newbaustellenrarr]);
              }
            }}
            columns={belagcolumns}
          />
        ) : (
          <></>
        )}
      </div>
    </Page>
  );
}
