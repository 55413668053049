import { useCallback } from "react";
import { Belagbestellung, Ladeliste } from "../gql/graphql";

export function useBelagsbestellungNew(
  lliste: (Ladeliste & {
    rowid: number;
  })[]
) {
  return useCallback(
    (pr: Belagbestellung & { rowid: number }) => {
      let sorte = pr.belagssorte ? pr.belagssorte + " " : "";
      let typ = pr.typ ? pr.typ + " " : "";
      let binder = pr.bindemittel ? pr.bindemittel + " " : "";
      let rc = pr.rc_Anteil ? pr.rc_Anteil : "";
      let belagsortestr = sorte + typ + binder + rc;
      let llmenge =
        lliste?.filter((pr2) => pr2.belags_Sorte === belagsortestr)?.length > 0
          ? lliste
              ?.filter((pr2) => pr2.belags_Sorte === belagsortestr)
              .map((it) => Number(it.menge ?? 0))
              ?.reduce((prev, curr) => prev + curr) ?? []
          : 0;
      return { ...pr, lLmenge: llmenge };
    },
    [lliste]
  );
}
