import React, { useCallback, useMemo } from "react";
import { durationToTime } from "../bestellung/hooks/useInitializeFormData";
import { IComboBox, TimePicker } from "@fluentui/react";

export function PreparedTimePicker(props: {
  value?: string;
  anchor: Date;
  disabled?:boolean;
  onChange: (time: string) => void;
}) {

  const handleChange = useCallback(
    (event: React.FormEvent<IComboBox>, time: Date) => {
      let Hourstringtmp =
        time.getHours() >= 1
          ? time.getHours() >= 10
            ? time.getHours() + ""
            : "0" + time.getHours()
          : "00";
      let Minutestringtmp =
        time.getMinutes() >= 1
          ? time.getMinutes() >= 10
            ? time.getMinutes() + ""
            : time.getMinutes() + "0"
          : "00";
      props.onChange(Hourstringtmp + ":" + Minutestringtmp);
    },
    [props]
  );

  const value = useMemo(() => {
    if (props.value) {
      let tmpDate = props.anchor;
      if (String(props.value).includes(":") === false) {
        let HoureMinute = durationToTime(
          String(props.value)
        ).split(":");
        tmpDate.setHours(Number(HoureMinute[0]), Number(HoureMinute[1]));
      } else {
        let HoureMinute = String(props.value).split(":");
        tmpDate.setHours(Number(HoureMinute[0]), Number(HoureMinute[1]));
      }

      return tmpDate;
    }
  }, [props]);
  return (
    <TimePicker
      placeholder="Bsp. 08:00"
      increments={10}
      allowFreeform
      disabled={props.disabled}
      autoComplete="on"
      dateAnchor={props.anchor}
      value={value}
      onChange={handleChange}
    />
  );
}
